import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import { formatDate } from 'utils/dateUtils';
import { setPaymentStartDate } from 'handlers/studentLoanData';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const NonProfitHireDate = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const dispatch = useDispatch();
  const { paymentStartDate } = useSelector(getStudentLoanData);
  const [hireDate, setHireDate] = useState<string | undefined>(paymentStartDate);
  const handleNext = () => {
    analytics.track('Student Loan Non-Profit Hire Date Selected', { hireDate });
    dispatch(setPaymentStartDate(new Date(hireDate!).toISOString()));
    onNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <DatePicker
            label="When did you start working in nonprofits?"
            selected={hireDate ? new Date(hireDate) : undefined}
            className={styles.datePicker}
            showMonthYearPicker
            placeHolder="MM/YYYY"
            onChange={(date) => setHireDate(formatDate(date))}
          />
          <p className={styles.hint}>An estimate is fine here. We’ll verify this later.</p>

          <Button disabled={Boolean(!hireDate)} onClick={handleNext} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default NonProfitHireDate;
