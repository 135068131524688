import Api from './Api';

export interface StudentLoanAssistanceData {
  eligible: boolean;
  paymentStartDate?: string;
  maxTerm?: number;
  totalBalance?: number;
}

export interface StudentLoanApi {
  getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData>;
}

export default class StudentLoanRestApi extends Api implements StudentLoanApi {
  async getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(`/studentLoan/assistanceData/${applicationId}`);
  }
}
