import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export const createStudentLoanAssistanceDataThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/getAssistanceData', async (applicationId: string, thunkApi) => {
    try {
      return await api.getStudentLoanAssistanceData(applicationId);
    } catch (error: any) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
