import { add, differenceInMonths, isBefore } from 'date-fns';
import { StudentLoanData } from 'handlers/studentLoanData';

const SKIPPED_MONTHS = 42; // March 2020 and September 2023

export const getRepaymentPeriods = ({ paymentStartDate, maxTerm }: StudentLoanData) => {
  const startDate = new Date(paymentStartDate!);

  // Standard repayment calculation:
  // Show the Standard Repayment as payment_start_date + the maximum of the student loan terms
  // + not counting any months between March 2020 and September 2023.
  let standardRepaymentEndDate = add(startDate, { months: maxTerm });
  if (isBefore(startDate, new Date(2020, 2))) {
    standardRepaymentEndDate = add(standardRepaymentEndDate, { months: SKIPPED_MONTHS });
  } else if (isBefore(startDate, new Date(2023, 8))) {
    standardRepaymentEndDate = add(new Date(2023, 8), { months: maxTerm });
  }

  // Show the Public Service Loan Forgiveness term as 10 years from payment_start_date
  const publicServiceRepaymentEndDate = add(startDate, { years: 10 });

  const monthDiff = differenceInMonths(standardRepaymentEndDate, publicServiceRepaymentEndDate);

  return {
    monthSavings: monthDiff,
    monthsToStandardRepayment: differenceInMonths(standardRepaymentEndDate, new Date()),
    monthsToPublicServiceRepayment: differenceInMonths(publicServiceRepaymentEndDate, new Date()),
  };
};

export const getRepaymentLabel = (monthDiff: number) =>
  monthDiff > 12
    ? `${Math.floor(monthDiff / 12)} year${monthDiff > 23 ? 's' : ''} earlier`
    : `${monthDiff} month${monthDiff > 1 ? 's' : ''} earlier`;

export const shouldDisplayOffer = ({ paymentStartDate, maxTerm }: StudentLoanData) => {
  const { monthSavings } = getRepaymentPeriods({ paymentStartDate, maxTerm });
  return paymentStartDate && isBefore(new Date(paymentStartDate), new Date()) && monthSavings > 0;
};
